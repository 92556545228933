@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: 'IQOSLight';
  src: local('IQOSLight'),
  url('fonts/IQOSSans-Light.otf') format('opentype');
}

@font-face {
  font-family: 'IQOSBold';
  src: local('IQOSBold'),
  url('fonts/IQOSSans-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'IQOSRegular';
  src: local('IQOSRegular'),
  url('fonts/IQOSSans-Regular.otf') format('opentype');
}

body {
  margin: 0;
  font-family: IQOSRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
#root {
  min-height: 100vh;
  max-width: 100%;
}

code {
  font-family: IQOSRegular;
}

:root {
  --primary: #00D1D2;
  --secondary: #34303D;

  --white: #FFFDFB;
  --gray: #34303D26;
  --disabled: #EAEAEA;
}


